<template>
  <div>
    <h2>Paperless Billing</h2>

    <div class="message-block">
      <b-alert
        v-if="!customerPaperlessData.isPaperlessEnabled"
        variant="warning"
        show
        class="warn-message paperless-block"
      >
        <div class="heading">Your account is set for paper billing</div>
        <div class="sub">
          All correspondence will be sent to the billing address on record via
          post. Please note, paper billing charges of &pound;2.50/invoice might
          apply to your account.
        </div>
        <div class="message">
          If you wish to opt in for paperless billing and receive all
          correspondence instantly, please select the 'Opt in' option below.
        </div>

        <div style="margin-top: 10px;">
          <b-button
            class="action"
            size="sm"
            @click="paperlessOptIn"
            variant="success"
            >Opt In</b-button
          >
        </div>
      </b-alert>
      <b-alert
        v-if="customerPaperlessData.isPaperlessEnabled"
        variant="success"
        show
        class="warn-message paperless-block"
      >
        <div class="heading">Your account is set for paperless billing</div>
        <div class="sub">All correspondence will be sent to you via email.</div>
        <div class="message">
          If you wish to opt out of paperless billing, please select the 'Opt
          out' option below. Paper billing charges of &pound;2.50/invoice might
          be applied to your account if you wish to receive paper correspondence
          instead.
        </div>

        <div style="margin-top: 10px;">
          <b-button
            class="action"
            size="sm"
            @click="paperlessOptOut"
            variant="success"
            >Opt Out</b-button
          >
        </div>
      </b-alert>
    </div>
    <div class="row" v-if="existingPaperlessEmails.length > 0">
      <div class="col-md-12">
        <div class="existingPaperless">
          <h5>Existing Paperless E-mails</h5>

          <div
            class="emailItem"
            v-for="email in existingPaperlessEmails"
            v-bind:key="email.index"
          >
            {{ email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paperlessOptIn } from "../../../components/mixins/paperless";

export default {
  name: "PaperlessBilling",
  mixins: [paperlessOptIn],
  props: {
    reference: {
      type: String,
      required: true
    },
    customerAccountType: {
      type: String
    },
    customerPaperlessData: {
      type: Object
    },
    isDismissPaperlessAuditExists: {
      type: Boolean
    },
    activeBillingAccounts: {
      type: Number
    }
  },
  computed: {
    existingPaperlessEmails() {
      let emails = [];
      if (this.customerAccountType !== "sub") {
        this.customerPaperlessData.leaseEmails.forEach(item => {
          emails.push(item);
        });
      }

      this.customerPaperlessData.subleaseEmails.forEach(item => {
        if (!emails.includes(item)) {
          emails.push(item);
        }
      });

      return emails;
    }
  },
  methods: {
    updateParent() {
      this.$emit("dashboard_refresh");
    },
    paperlessOptOut() {
      let optOutCheckbox = "";

      if (this.activeBillingAccounts > 0) {
        optOutCheckbox =
          '<div id="swal-content1" class="mt-3">Opt out on all apartments</div>' +
          '<input id="swal-input1" class="swal2-input" type="checkbox">';
      }

      this.$swal({
        title: "Paperless Opt Out?",
        html:
          '<strong style="font-weight:bold;">Paper bill charges will be applicable</strong>' +
          optOutCheckbox,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const id = this.reference;
          const applyToRelatedAccounts = document.getElementById("swal-input1")
            .checked;

          return this.$store
            .dispatch("paperlessOptOut", { id, applyToRelatedAccounts })
            .then(() => {
              const title = "Paperless Opt Out";
              const subtitle = "Paperless Opt Out has been successful";
              this.$store.dispatch("pushSuccess", { title, subtitle });
              this.updateParent();
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was a problem storing the data: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            })
            .finally(() => {
              this.submitting = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

.message-block {
  margin-top: 20px;
}

.warn-message {
  div {
    font-size: 0.9rem;
    padding: 5px 0px;
  }
  div.date {
    font-size: 0.8rem;
    padding-top: 0px;
  }
}

.existingPaperless {
  text-align: left;
  padding-left: 10px;

  .emailItem {
    color: #666;
  }
}

.paperless-block {
  .heading {
    font-weight: bold;
  }

  .sub {
    font-size: 0.8rem;
    margin-top: 15px;
    font-weight: bold;
  }

  .message {
    font-size: 0.8rem;
    margin-top: 10px;
  }
}
</style>
