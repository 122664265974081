export const paperlessOptIn = {
  methods: {
    paperlessOptIn() {
      this.$swal({
        title: "Opt In for Paperless?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const id = this.reference;
          return this.$store
            .dispatch("paperlessOptIn", { id })
            .then(() => {
              const title = "Opted In for Paperless";
              const subtitle = "Email added to paperless";
              this.$store.dispatch("pushSuccess", { title, subtitle });
              this.updateParent();
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was a problem storing the data: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            })
            .finally(() => {
              this.submitting = false;
            });
        }
      });
    }
  }
};
